import React, { useState, useEffect } from "react";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  LinearProgress,
  Box,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { More, Close as CloseIcon } from "@mui/icons-material";
import { randomId } from "@mui/x-data-grid-generator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useGroupAuth from "../../services/CheckUserGroup";

import "./style.css";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("en");

// Set default timezone to CST
dayjs.tz.setDefault("America/Chicago");

const BedsDetailedActivityButton = ({ selectedFacilityCode }) => {
  const { authState } = useOktaAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const checkUserGroup = useGroupAuth();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function sortBedsArray(bedsArray) {
    return bedsArray.sort((a, b) => {
      // Sort by isOccupied ("Occupied" first)
      if (a.isOccupied === "Occupied" && b.isOccupied !== "Occupied") {
        return -1;
      }
      if (a.isOccupied !== "Occupied" && b.isOccupied === "Occupied") {
        return 1;
      }
  
      // If isOccupied is the same, sort by bedType alphabetically
      if (a.bedType < b.bedType) {
        return -1;
      }
      if (a.bedType > b.bedType) {
        return 1;
      }
  
      // If bedType is also the same, sort by roomNumber alphabetically
      if (a.roomNumber < b.roomNumber) {
        return -1;
      }
      if (a.roomNumber > b.roomNumber) {
        return 1;
      }
  
      return 0; // If all are the same
    });
  }
  useEffect(() => {
    if (open) {
      setLoading(true);
      const fetchData = async () => {
        const url =
          process.env.REACT_APP_BASE_API +
          `/bed-roster-activity/${selectedFacilityCode}`;
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: bearerToken,
              "Content-Type": "application/json",
            },
          });
          let updatedData = response.data.map((obj) => {
            if (obj) {
              return {
                ...obj,
                id: randomId()
              };
            }
          });
          updatedData = sortBedsArray(updatedData)
          setData(updatedData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [open]);


  // Function to determine the row class name based on conditions
  const getRowClassName = (params) => {
    const { isOccupied, bedStatus, bedType } = params.row;

    if (isOccupied === "Occupied" && bedStatus === "Active") {
      return "green-row"; // Green background
    }

    if (isOccupied === "Occupied" && bedType === "Not Yet Classified") {
      return "red-row"; // Red background
    }

    if (isOccupied === "Not Occupied" && bedType === "Not Yet Classified") {
      return "orange-row"; // Orange background
    }

    if (
      isOccupied === "Occupied" &&
      bedStatus === "Not Active" &&
      ["Surgical/Procedure", "Rehab", "SUD"].includes(bedType)
    ) {
      return ""; // No color change
    }

    // Default return if no conditions match
    return "";
  };

  const columns = [
    { field: "facilityName", headerName: "Facility Name", width: 250 },
    { field: "roomNumber", headerName: "Room Number", width: 120 },
    { field: "bedNumber", headerName: "Bed Number", width: 100 },
    { field: "bedType", headerName: "Bed Type", width: 180 },
    { field: "bedStatus", headerName: "Bed Status", width: 150 },
    { field: "staffed", headerName: "Staffed", width: 120 },
    {
      field: "lastAdmitDate",
      headerName: "Last Admission/Start of Use of that Room Date",
      width: 220,
      renderCell: (params) => 
        params.value ? dayjs.tz(params.value, "America/Chicago").format("MM/DD/YYYY hh:mm A") : "",
    },
    {
      field: "lastDischargeDate",
      headerName: "Last Discharge Date/End of Use of that Room Date",
      width: 220,
      renderCell: (params) => 
        params.value ? dayjs.tz(params.value, "America/Chicago").format("MM/DD/YYYY hh:mm A") : "",
    },
    {
      field: "lastMessageReceivedDate",
      headerName: "Last Message Received Date",
      width: 220,
      renderCell: (params) => 
        params.value ? dayjs.tz(params.value, "America/Chicago").format("MM/DD/YYYY hh:mm A") : "",
    },
    {
      field: "lastUpdate",
      headerName: "Last Updated",
      width: 220,
      renderCell: (params) => 
        params.value ? dayjs.tz(params.value, "America/Chicago").format("MM/DD/YYYY hh:mm A") : "",
    },
    {
      field: "isOccupied",
      headerName: "Occupancy",
      width: 200,
    },
    {
      field: "mrn",
      headerName: "MRN",
      width: 200,
    },
  ];
  

  const Legend = () => (
    <Box className="legend-container">
      <Box className="legend-item">
        <Box className="legend-color-box green-box" />
        <Typography fontSize={15}>Currently occupied beds</Typography>
      </Box>
      <Box className="legend-item">
        <Box className="legend-color-box red-box" />
        <Typography fontSize={15}>
          Correction Needed for Not Yet Classified Beds
        </Typography>
      </Box>
      <Box className="legend-item">
        <Box className="legend-color-box orange-box" />
        <Typography fontSize={15}>
          Correction Needed for Not Yet Classified Beds
        </Typography>
      </Box>
    </Box>
  );
  const hiddenFields = ['mrn'];

  const getTogglableColumns = (columns) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{ marginRight: "5px" }}
        onClick={handleClickOpen}
        startIcon={<More />}
      >
        Beds Detailed Activity
      </Button>
      <Dialog maxWidth="xl" open={open} onClose={handleClose}>
        <DialogTitle>
          <ListItem>
            <ListItemIcon>
              <Avatar variant="rounded" sx={{ bgcolor: "#2F5E8B" }}>
                <More fontSize="small" />
              </Avatar>
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="h6">Beds Detailed Activity</Typography>
            </ListItemText>
          </ListItem>
          <Legend /> {/* Add the Legend component here */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", right: 16, top: 16 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div style={{ height: "80vh", width: "100%" }}>
            <DataGridPro
              slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress,
              }}
              slotProps={{
                columnsManagement: {
                  getTogglableColumns,
                },
                toolbar: {
                  csvOptions: { allColumns: true },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              loading={loading}
              rows={data}
              columns={columns}
              getRowClassName={getRowClassName} // Add this prop
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    mrn: checkUserGroup("CyncHealth_IA_Beds_Internal_Admins") ? true : false ,
                   
                  },
                },
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BedsDetailedActivityButton;
