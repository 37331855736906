/* eslint-disable */
import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import "../layout/Navbar.css";
import { Box, Button, Card } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person2Rounded";
import HotelIcon from "@mui/icons-material/HotelRounded";
import WelcomeMessage from "../pages/welcome/Welcome";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  AdminPanelSettings,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  KeyboardArrowDown,
  PowerSettingsNew,
  ReceiptLongOutlined,
  SyncAlt,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";

const drawerWidth = 250;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  introPaper: {},
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "left",
    justifyContent: "flex-end",
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "120px",
    textAlign: "center",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.action.active,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Navbar = ({ setCorsErrorModalOpen }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [userInfo, setUserInfo] = useState("");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";
  const login = async () =>
    oktaAuth.signInWithRedirect({ originalUri: "/menu" });

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        {authState.isAuthenticated && (
          <>
            <AppBar
              style={{
                textDecoration: "none",
                backgroundColor: "#2F5E8C",
              }}
              open={open}
              position="fixed"
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon></MenuIcon>
                </IconButton>

                <img
                  src={process.env.PUBLIC_URL + "/CHicon.png"}
                  width="35"
                  height="35"
                  className="d-inline-block align-top"
                  alt=""
                ></img>
                <Typography
                  className="navbar-brand p-2"
                  fontWeight="fontWeightBold"
                  style={{ fontSize: "18px" }}
                >
                  CyncHealth
                  <Typography style={{ fontSize: "12px" }}>
                    IOWA Dashboard
                  </Typography>
                </Typography>

                <span className="example-fill-remaining-space"></span>

                {handleGroupAuth("IDPH_IA_Dashboard_Admins") ? (
                  <Link to="/admin">
                    <Tooltip
                      disableFocusListener
                      title="Admin Panel"
                      placement="top-end"
                      arrow
                    >
                      <IconButton color="primary" aria-label="open admin panel">
                        <AdminPanelSettings style={{ color: "white" }} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                ) : (
                  ""
                )}

                <Link to="/menu">
                  <Tooltip
                    disableFocusListener
                    title="Menu"
                    placement="top-end"
                    arrow
                  >
                    <IconButton color="primary" aria-label="open menu">
                      <Dashboard style={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>
                </Link>

                <IconButton
                  color="primary"
                  onClick={handleClick}
                  aria-label="open more options"
                >
                  <PersonIcon style={{ color: "white" }}></PersonIcon>
                  <KeyboardArrowDown
                    style={{ fontSize: "small", color: "white" }}
                  />
                </IconButton>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link
                    to="/profile"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <StyledMenuItem>
                      <ListItemText> Profile </ListItemText>
                    </StyledMenuItem>
                  </Link>

                  <Link
                    to="/contactus"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <StyledMenuItem>
                      <ListItemText> Support </ListItemText>
                    </StyledMenuItem>
                  </Link>

                  {handleGroupAuth("IDPH_IA_Dashboard_Admins") ? (
                    <Link
                      to="/admin"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <StyledMenuItem>
                        <ListItemText> Admin Panel </ListItemText>
                      </StyledMenuItem>
                    </Link>
                  ) : (
                    " "
                  )}
                </StyledMenu>

                <Button
                  color="secondary"
                  variant="contained"
                  id="logout-button"
                  onClick={logout}
                  endIcon={<PowerSettingsNew />}
                >
                  {" "}
                  Logout
                </Button>
              </Toolbar>
            </AppBar>
          </>
        )}

        {authState.isAuthenticated && (
          <Drawer variant="permanent" open={open}>
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose} aria-label="close drawer">
                {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </div>
            <Divider />
            <Card
              onMouseOver={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
            >
              <Link
                to="/menu"
                style={{ textDecoration: "none", color: "grey" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText> Menu</ListItemText>
                </ListItem>
              </Link>

              {handleGroupAuth("IDPH_IA_Bed_Occupancy_Users") ? (
                <Link
                  to="/bedcapacity"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <HotelIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {" "}
                      Bed Capacity{" "}
                      {/* <span className="livebadge pulsate">NRT</span> */}
                    </ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("IDPH_IA_Bed_Roster_Admins") ? (
                <Link
                  to="/bedsroster"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <SyncAlt />
                    </ListItemIcon>
                    <ListItemText> Bed Capacity Roster</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}

              {handleGroupAuth("IDPH_IA_BED_ROSTER_LOGS_DHHS_USERS") ? (
                <Link
                  to="/bedsroster-log-book"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <ReceiptLongOutlined />
                    </ListItemIcon>
                    <ListItemText> Bed Roster Logs</ListItemText>
                  </ListItem>
                </Link>
              ) : (
                ""
              )}
            </Card>
          </Drawer>
        )}

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div>
            {!authState.isPending && !authState.isAuthenticated && (
              <WelcomeMessage />
            )}
          </div>

          {/* <div className="footer">
          <Grid container spacing={1} className="mb-3">
            <Grid item xs={5} className="text-center">
             © 2021 All Rights Reserved.
            </Grid>
            <Grid item xs={3} className="text-center">
            Powered by: <a href="https://cynchealth.org/" target="_blank" style={{ textDecoration: 'none', color:'#3F51B5'}} > CyncHealth</a>
            </Grid>
            <Grid item xs={3} className="text-center">
            <a href="https://cynchealth.org/website-privacy/" target="_blank" style={{ textDecoration: 'none', color:'#3F51B5'}}> Privacy Policy</a>
            </Grid>
          </Grid>
           </div> */}
        </main>
      </div>
    </>
  );
};
export default Navbar;
